import React, { useState } from "react"
import { PageLayout } from "../components/Layout"
import SEO from "../components/SEO"
import TextSectionBackground from "../components/TextSectionBackground"
import styled from "styled-components"

const Wrapper = styled.div`
    max-width: 1200px;
    margin: 3rem auto;
    p, h3, ul, li {
    text-align: left;
    color: #444444;
    }
`

const PrivacyPolicy = () => (
    <PageLayout>
        <SEO title="Privacy Policy"
         description="Mavela Game Lodge takes seriously the privacy of those that visit our website. This Privacy Policy aims to communicate what information we collect and how that information is used and safeguarded."
        />
        <TextSectionBackground>
            <Wrapper>
                <h1>Privacy Policy</h1>
                <p>
                    Mavela Game Lodge takes seriously the privacy of those that visit our website. 
                    We have established this Privacy Policy to communicate what information we 
                    collect and how that information is used and safeguarded.
                </p>
                <p>
                    By accessing this website (mavela.co.za), you acknowledge the terms and 
                    conditions expressed herein. We reserve the right to change this policy at our 
                    sole discretion and without notice.
                </p>
            </Wrapper>
            <Wrapper>
            <h3>
                WHAT INFORMATION IS COLLECTED, AND WHY?
            </h3>
            <p>
                When a user visits our website, we collect information about the visit that 
                does not identify the user personally. We track information such as the domain 
                from which the user is visiting and the user’s browser type. We also collect 
                specific information regarding the user’s session on our website. This includes 
                items such as the date and time of the visit and the pages viewed.
            </p>
            <p>
                Sometimes we ask a user to voluntarily provide personally identifiable information 
                (PII). This information generally includes, but is not limited to, name, e-mail 
                address, postal address, and telephone number. We request this information when 
                the user requests one of the following:
            </p>
            <ul>
                <li>
                    To register to attend an on-line seminar or demonstration
                </li>
                <li>
                    To receive specific information from us such as a whitepaper or code sample
                </li>
                <li>
                    To sign up for a mailing list
                </li>
                <li>
                    To correspond with us
                </li>
                <li>
                    Or any other such activity that we deem necessary
                </li>
            </ul>
            <p>
                Mavela Game Lodge does not collect any personally identifiable financial or 
                health-related information. We do not intentionally collect information from 
                children under the age of 13.
            </p>
            </Wrapper>
            <Wrapper>
                <h3>
                    HOW IS THE INFORMATION USED?
                </h3>
                <p>
                    Non-personally identifiable information (non-PII) is collected to allow us to 
                    analyze how our website is used and to improve the content and service the website 
                    provides.
                </p>
                <p>
                    Personally identifiable information is collected for analysis to improve the 
                    content of the website and the services we provide the user.
                </p>
                <p>
                    Mavela Game Lodge owns the collected information and will not sell, trade, or rent 
                    the information to others. We may use the information to continue contact with the 
                    user. This may include contact such as follow-up calls, e-mail correspondence, or 
                    mail correspondence to follow-up on the contact or to provide marketing information 
                    about promotions, new services, or important website changes. Mavela Game Lodge may 
                    share this information with its business partners for the specific purpose of a 
                    promotion or service offering.
                </p>
            </Wrapper>
            <Wrapper>
                <h3>
                    ARE “COOKIES” USED ON THE WEBSITE?
                </h3>
                <p>
                    “Cookies” are small pieces of information that are placed on a web user’s hard drive. 
                    We may use cookies to provide you with better service. Cookies are pieces of 
                    information that a Website transfers to your computer’s hard disk for record-keeping 
                    purposes. Cookies can make the Web more useful by storing information about your 
                    preferences for a particular site. The use of cookies is an industry standard, and 
                    many major Websites use them to provide useful features for their customers. Cookies 
                    in and of themselves do not personally identify users, although they do identify a 
                    user’s computer. Most browsers are initially set to accept cookies. If you prefer, 
                    you can set your browser to refuse cookies. However, you may not be able to take full 
                    advantage of the functionality of the Mavela Game Lodge site if you do so.
                </p>
            </Wrapper>
            <Wrapper>
                <h3>
                    HOW IS PERSONALLY IDENTIFIABLE INFORMATION SAFEGUARDED?
                </h3>
                <p>
                    Mavela Game Lodge utilizes industry standard methods and mechanisms such as firewalls, 
                    intrusion monitoring, and passwords to protect electronic information. Multiple 
                    physical security methods such as locking devices and 24 hour premise monitoring are 
                    also employed. Finally, access to PII is limited to a small number of personnel and on 
                    a need-to-know basis.
                </p>
            </Wrapper>
            <Wrapper>
                <h3>
                    LINKS TO OTHER WEBSITES
                </h3>
                <p>
                    Mavela Game Lodge web site may contain links to other internet web sites. Mavela Game 
                    Lodge neither controls nor endorses external sites and is not responsible for the content 
                    of such sites. This policy does not cover the privacy policies and data collection 
                    practices of non-Mavela Game Lodge web sites.
                </p>
            </Wrapper>
            <Wrapper>
                <h3>
                    HOW TO OPT OUT OF CORRESPONDENCE
                </h3>
                <p>
                    Based on the information a user has provided, Mavela Game Lodge may send e-mail 
                    correspondence to the user. Each correspondence will contain an easy means to opt out of 
                    further correspondence.
                </p>
            </Wrapper>
        </TextSectionBackground>
      
    </PageLayout>
  )

export default PrivacyPolicy